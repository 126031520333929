<template>
  <div>
    <Header />
    <pageTitle :txt="ttl" :backBtn="backBtn" />
    <div id="meta">
      <div v-on:click="(edit = !edit), (overlay = !overlay)" class="edit-btn">
        <btnS class="edit" btnTxt="編集" color="positive1" />
      </div>
      <div
        v-on:click="(print = !print), (overlay = !overlay)"
        class="print-btn"
      >
        <btnS class="print" btnTxt="印刷" color="primary" />
      </div>
    </div>
    <div id="wrapper">
      <ul class="list">
        <li class="list-head">
          <div class="list-head__item col-name">摘要</div>
          <div class="list-head__item col-sales">区分</div>
          <div class="list-head__item col-sales">税抜価格</div>
          <div class="list-head__item col-sales">消費税</div>
          <div class="list-head__item col-taxin">税込価格</div>
          <div class="list-head__item col-note">備考</div>
        </li>
        <div>
          <li class="list-item" v-for="(row, key) in list" :key="key">
            <div class="list-item__data col-name">{{ row.name }}</div>
            <div class="list-item__data col-sales number">
              {{ row.tax_class }}%
            </div>
            <div class="list-item__data col-sales number">
              {{ row.total_price | comma }}
            </div>
            <div class="list-item__data col-sales number">
              {{ row.tax_price | comma }}
            </div>
            <div class="list-item__data col-taxin number">
              {{ row.total_tax_price | comma }}
            </div>
            <div class="list-item__data col-note">{{ row.note }}</div>
          </li>
          <li class="list-item total">
            <div class="list-item__data col-name">合計</div>
            <div class="list-item__data col-sales number"></div>
            <div class="list-item__data col-sales number">
              {{ total.total_price | comma }}
            </div>
            <div class="list-item__data col-sales number">
              {{ total.tax_price | comma }}
            </div>
            <div class="list-item__data col-taxin number">
              {{ total.total_tax_price | comma }}
            </div>
            <div class="list-item__data col-note"></div>
          </li>
        </div>
      </ul>
    </div>

    <div id="overlay" :class="{ visible: overlay }">
      <div id="print" v-if="print">
        <div id="print-inner">
          <div id="print-meta">
            <p class="print-ttl">請求書</p>

            <p class="print-period">
              {{ store.start_date }} ～ {{ store.end_date }}
            </p>
            <div class="print-time">
              {{ store.now_date }} 現在
              <br />
              1/1頁
              <br />
              <br />
              有限会社イセヤ
            </div>
            <p class="print-address">
              〒{{ store.zipcode }}<br />
              {{ store.address }}<br />
              {{ store.store_name }} 御中
            </p>
          </div>
          <ul class="list">
            <div>
              <li class="list-item" v-for="(row, key) in list" :key="key">
                <div class="list-item__data col-name">{{ row.name }}</div>
                <div class="list-item__data col-sales number">
                  {{ row.tax_class }}%
                </div>
                <div class="list-item__data col-sales number">
                  {{ row.total_price | comma }}
                </div>
                <div class="list-item__data col-sales number">
                  {{ row.tax_price | comma }}
                </div>
                <div class="list-item__data col-taxin number">
                  {{ row.total_tax_price | comma }}
                </div>
                <div class="list-item__data col-note">{{ row.note }}</div>
              </li>
              <li class="list-item total">
                <div class="list-item__data col-name">合計</div>
                <div class="list-item__data col-sales number"></div>
                <div class="list-item__data col-sales number">
                  {{ total.total_price | comma }}
                </div>
                <div class="list-item__data col-sales number">
                  {{ total.tax_price | comma }}
                </div>
                <div class="list-item__data col-taxin number">
                  {{ total.total_tax_price | comma }}
                </div>
                <div class="list-item__data col-note"></div>
              </li>
            </div>
          </ul>
        </div>
      </div>
      <div id="fixedMenu" v-if="print">
        <div v-on:click="(print = !print), (overlay = !overlay)">
          <btnS btnTxt="戻る" color="back" />
        </div>
        <div v-on:click="(print = !print), (overlay = !overlay)">
          <btnL btnTxt="この内容で印刷する" color="primary" />
        </div>
      </div>

      <div id="edit" v-if="edit">
        <div class="dialog">
          <p class="dialog-ttl">請求情報編集</p>
          <div class="dialog-form">
            <div class="dialog-form__item name">
              <label>摘要</label>
              <input type="text" v-model="invoiceName" />
              <div class="error">{{ errors.name }}</div>
            </div>
            <div class="dialog-form__item tax">
              <label>税区分</label>
              <div class="select">
                <select v-model="tax_class">
                  <option value="8">8%</option>
                  <option value="10">10%</option>
                </select>
                <div class="error">{{ errors.tax_class }}</div>
              </div>
            </div>
            <div class="dialog-form__item price">
              <label>税抜価格</label>
              <input type="text" v-model="total_price" />
              <div class="error">{{ errors.total_price }}</div>
            </div>
            <div class="dialog-form__item note">
              <label>備考</label>
              <input type="text" v-model="note" />
              <div class="error">{{ errors.note }}</div>
            </div>
          </div>
        </div>
        <div class="overlay-btn">
          <div v-on:click="(edit = !edit), (overlay = !overlay)">
            <btnS btnTxt="戻る" color="back" />
          </div>
          <div>
            <btnL btnTxt="この内容で登録する" color="primary" @click="save" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;

#meta {
  width: 100%;
  height: 75px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  padding: 0 30px 0 15px;
  position: relative;
}

.edit-btn {
  margin-left: auto;
}

.print-btn {
  margin-left: 30px;
}

#wrapper {
  box-sizing: border-box;
  padding: 0 0 160px;
}

.list {
  box-sizing: border-box;
  @extend .f_body;
  display: flex;
  flex-wrap: wrap;
  width: 1282px;
  margin: 0 auto;
  border-left: solid 1px variables.$bg-gray-2;
}

.list-head {
  display: flex;
  height: 60px;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
  box-sizing: border-box;
}

.list-head__item {
  height: 100%;
  box-sizing: border-box;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-item {
  display: flex;
  flex-wrap: wrap;
  height: 60px;
  position: relative;
  &.total {
    &:before {
      content: "";
      width: 100%;
      min-width: 1280px;
      height: 1px;
      background: variables.$txt-default;
      position: absolute;
      left: 0;
      top: 0;
    }
    &:after {
      content: "";
      width: 100%;
      min-width: 1280px;
      height: 1px;
      background: variables.$txt-default;
      position: absolute;
      top: -2px;
      left: 0;
    }
  }

  &:nth-of-type(even) {
    background: variables.$bg-gray-1;
  }
}

.list-item__data {
  box-sizing: border-box;
  padding: 0 15px;
  height: 100%;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  display: flex;
  align-items: center;
  &.number {
    justify-content: flex-end;
  }
  &.negative {
    color: variables.$negative;
  }
}

.col-name {
  width: 450px;
}

.col-sales {
  width: 130px;
}

.col-taxin {
  width: 140px;
}

.col-note {
  width: 300px;
}

#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(59, 64, 67, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}
#print {
  width: 668px;
  height: 945px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px;

  position: absolute;
  left: 50%;
  top: 20px;
  transform: translate(-50%, 0);
  .list {
    width: 606px;
    //transform: scale(0.69);
    transform-origin: top left;
    font-size: 1rem;
  }
  .col-name {
    width: 155px;
  }
  .col-sales,
  .col-taxin {
    width: 90px;
  }
  .col-note {
    width: 90px;
  }
}

#print-inner {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#print-meta {
  margin-bottom: 30px;
}

.print-time {
  position: absolute;
  right: 30px;
  top: 50px;
  font-size: 1rem;
  letter-spacing: 0.06em;
  text-align: right;
}

.print-ttl {
  text-align: center;
  @extend .f_pageTtl;
  margin-bottom: 10px;
}

.print-period {
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 0.06em;
  margin-bottom: 30px;
}

.print-shopName,
.print-shopTel {
  font-size: (1.5 * 0.69rem);
  letter-spacing: 0.06em;
  margin-right: (30 * 0.69px);
}

#fixedMenu {
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 89px;
  background: transparent;
  backdrop-filter: blur(5.3040900230407715px);
  --webkit-backdrop-filter: blur(5.3040900230407715px);
  background-color: rgba(255, 255, 255, 0.33);

  display: flex;
  justify-content: center;
  align-items: center;
  .buttonS {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0, -50%);
  }
}

#add,
#edit {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog {
  box-sizing: border-box;
  width: 800px;
  padding: 30px 50px 50px;
  background: #fff;
  margin: 0 auto 25px;
}

.dialog-ttl {
  @extend .f_pageTtl;
  margin-bottom: 15px;
  text-align: center;
}

.dialog-form {
  display: flex;
  flex-wrap: wrap;
}

.dialog-form__item {
  @extend .f_caption;
  margin-top: 35px;
  &.name {
    width: 220px;
  }
  &.tax {
    width: 160px;
  }
  &.price {
    width: 120px;
  }
  &.note {
    width: 400px;
  }
  &.name,
  &.tax {
    margin-right: 20px;
  }
  label {
    margin-bottom: 5px;
    display: block;
  }
  input,
  select {
    box-sizing: border-box;
    border: solid 1px variables.$bg-gray-3;
    border-radius: 3px;
    background: #fff;
    width: 100%;
    height: 28px;
    padding: 0 12px;
  }
  .select {
    position: relative;
    &:after {
      content: "";
      pointer-events: none;

      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: variables.$txt-default transparent transparent transparent;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%);
    }
  }
}

.overlay-btn {
  display: flex;
  justify-content: center;
  width: 800px;
  height: 60px;
  position: relative;
  margin: 0 auto;
  .buttonS {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}
</style>
<style lang="scss">
.meta-calendar__input {
  color: rgba(255, 255, 255, 0);
}

@media print {
  @page {
    size: portrait;
    margin: 0;
  }
  #wrapper,
  #fixedMenu {
    display: none !important;
  }
  #print {
    width: 251mm;
    height: 172mm;
    page-break-after: always;

    .list {
      transform: scale(1);
    }
  }
}
</style>
<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import btnS from "@/components/btn/btnS";
import btnL from "@/components/btn/btnL";
import Global from "@/components/mixins/Global.vue";
import ApiClient from "@/module/api";

export default {
  name: "ShopHome",
  components: {
    Header,
    pageTitle,
    btnS,
    btnL
  },
  mixins: [Global],
  data: function() {
    return {
      backBtn: true,
      print: false,
      today: "",
      overlay: false,
      edit: false,
      ttl: "当月の請求予定",
      shopName: "",
      list: [],
      total: {},
      store: {},
      invoiceName: "",
      tax_class: "8",
      total_price: 0,
      note: "",
      errors: {}
    };
  },
  props: {
    name: String
  },
  async created() {
    this._redraw();
  },
  methods: {
    async save() {
      this.errors = {};
      const param = {
        store_id: this.$route.query.shopID,
        target_date: this.ymdFormat(new Date()),
        name: this.invoiceName,
        tax_class: this.tax_class,
        total_price: this.total_price,
        tax_price: 0,
        total_tax_price: 0,
        note: this.note
      };
      param.total_tax_price = parseInt(
        this.total_price * (1 + this.tax_class / 100),
        10
      );
      param.tax_price = param.total_tax_price - param.total_price;
      const api = new ApiClient();
      const result = await api.post("/iseya/invoice/save", param);
      const checkResult = api.getErrors(result);
      if (checkResult.isHit) {
        this.errors = checkResult.errors;
        return;
      }
      this.edit = !this.edit;
      this.overlay = !this.overlay;
      this._redraw();
    },
    async _redraw() {
      const api = new ApiClient();
      const param = {
        store_id: this.$route.query.shopID
      };
      const result = await api.post("/iseya/invoice/detail", param);
      this.list = result.list;
      this.total = result.total;
      this.store = result.store;
    }
  }
};
</script>
